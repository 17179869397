
import headerMenuOverlayUtility from '@/mixins/headerMenuOverlayUtility';

export default {
  name: 'AboutUsViewHeaderMobile',
  mixins: [headerMenuOverlayUtility],
  props: {
    headerData: {
      type: Object,
    },
  },
  data() {
    return {
      nodeId: 'header-menu-mobile',
      isMounted: false,
      closeOnClick: false,
      isChildActive: false,
      menuItems: [
        {
          title: 'About Us',
          slug: 'about-us',
        },
        {
          title: 'Our Story',
          slug: 'our-story',
        },
        {
          title: 'The 1Stop Advantage',
          slug: '1stop-advantage',
        },
        {
          title: '1Stop Cares',
          slug: '1stop-cares',
        },
        {
          title: 'Careers',
          slug: 'careers',
        },
        {
          title: 'Partner With Us',
          slug: 'partner-with-us',
        },
        {
          title: 'Contact Us',
          slug: 'about-contact-us',
        },
      ],
    };
  },
  watch: {
    menu(newValue) {
      this.$emit('toggle-mobile-menu', newValue);
    },
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    vDialogContentClasses() {
      const classes = [
        this.$style.mobileMenuWrapper,
      ];

      if (this.isChildActive) {
        classes.push(this.$style.isDisabled);
      }

      return classes;
    },
  },
  methods: {
    closeDialog() {
      this.toggleMenu();
    },
    changeStatusChild(status) {
      this.isChildActive = status;
    },
  },
};

