import { render, staticRenderFns } from "./FooterDesktop.vue?vue&type=template&id=3fa4a555"
import script from "./FooterDesktop.vue?vue&type=script&lang=js"
export * from "./FooterDesktop.vue?vue&type=script&lang=js"
import style0 from "./FooterDesktop.vue?vue&type=style&index=0&id=3fa4a555&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterNavigationMenu: require('/var/www/html/js/productlisting/components/templates/footer/navigation/Menu.vue').default,FooterContacts: require('/var/www/html/js/productlisting/components/templates/footer/Contacts.vue').default,AboutUsViewReviewContainer: require('/var/www/html/js/productlisting/components/templates/aboutUs/view/ReviewContainer.vue').default,FooterScrollTop: require('/var/www/html/js/productlisting/components/templates/footer/ScrollTop.vue').default})
