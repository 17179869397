import { render, staticRenderFns } from "./Desktop.vue?vue&type=template&id=cde90d3a"
import script from "./Desktop.vue?vue&type=script&lang=js"
export * from "./Desktop.vue?vue&type=script&lang=js"
import style0 from "./Desktop.vue?vue&type=style&index=0&id=cde90d3a&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/var/www/html/js/productlisting/components/common/BaseIcon.vue').default,HeaderMenuBrandsCatalog: require('/var/www/html/js/productlisting/components/templates/header/menu/brands/Catalog.vue').default,HeaderMenuBrandsLuxury: require('/var/www/html/js/productlisting/components/templates/header/menu/brands/Luxury.vue').default})
