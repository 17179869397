import { render, staticRenderFns } from "./two_column_with_left_sidebar.vue?vue&type=template&id=523ea2de"
import script from "./two_column_with_left_sidebar.vue?vue&type=script&lang=js"
export * from "./two_column_with_left_sidebar.vue?vue&type=script&lang=js"
import style0 from "./two_column_with_left_sidebar.vue?vue&type=style&index=0&id=523ea2de&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AboutUsViewHeader: require('/var/www/html/js/productlisting/components/templates/aboutUs/view/Header.vue').default,AboutUsViewFooter: require('/var/www/html/js/productlisting/components/templates/aboutUs/view/Footer.vue').default,TheLoading: require('/var/www/html/js/productlisting/components/templates/TheLoading.vue').default})
