
/**
 * The main layout
 * @category [Layouts]
 * @nuxt-listeners [
 *   'change-global-loading', 'change-global-scrolling', 'change-global-overlay'
 * ]
 */
import { GET_META_DATA } from '@/api/apolloCommonQueries';
import { GET_ABOUT_US_HEADER } from '~/api/apolloAboutUsQueries';
import headerMenuOverlayUtility from '~/mixins/headerMenuOverlayUtility';
import respUtility from '@/mixins/respUtility';

export default {
  name: 'simpleLayout',
  mixins: [
    headerMenuOverlayUtility,
    respUtility,
  ],
  components: {
    TheLoading: () => import('~/components/templates/TheLoading.vue'),
  },
  data() {
    return {
      redirect: null,
      overlay: false,
      hasOverlayWithDelay: false,
      loadingPopup: false,
      footerData: null,
      headerData: null,
      metaData: null,
    };
  },
  async fetch() {
    await this.fetchData();
  },
  computed: {
    headData() {
      return this.$store.state.aboutUs?.aboutMetaData?.addons?.head;
    },
  },
  watch: {
    overlay(val) {
      /*
       * The "hasOverlayWithDelay" property changes to false with a delay.
       * This is to remove the "hasOverlay" class with a delay.
       * This prevents clicking on site elements under the <v-overlay>.
       */

      if (val) {
        this.hasOverlayWithDelay = val;
      } else {
        new Promise((res) => {
          setTimeout(res, 500);
        }).then(() => {
          this.hasOverlayWithDelay = val;
        });
      }
    },
    '$route.path': function watchRoute() {
      this.$nextTick(() => {
        this.getMetadata();
      });
    },
  },
  created() {
    this.$nuxt.$on('change-global-loading', (status) => {
      this.loadingPopup = status;
    });
  },
  head() {
    return {
      title: this.headData?.title || '1StopBedrooms',
    };
  },
  methods: {
    async getMetadata() {
      this.redirect = null;
      const parameters = Object.entries(this.$route.query)
        .map(([name, value]) => ({ name, value: (value || '').split('$,$') }));
      const result = await this.$graphql.default.request(GET_META_DATA, {
        slug: this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path,
        parameters: parameters.length ? parameters : null,
      });
      const redirect = result.pageMetadata?.addons?.redirect?.url;
      if (redirect) {
        this.redirect = redirect[0] === '/' ? redirect : `/${redirect}`;
        window.location.href = this.redirect;
        return;
      }
      this.$store.commit('aboutUs/setAboutMetaData', result.pageMetadata || {});
    },
    async fetchData() {
      const f1 = async () => {
        const parameters = Object.entries(this.$route.query)
          .map(([name, value]) => ({ name, value: (value || '').split('$,$') }));
        const result = await this.$graphql.default.request(GET_META_DATA, {
          slug: this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path,
          parameters: parameters.length ? parameters : null,
        });
        const redirect = result.pageMetadata?.addons?.redirect?.url;
        if (redirect) {
          this.redirect = redirect[0] === '/' ? redirect : `/${redirect}`;
          window.location.href = this.redirect;
          return;
        }
        this.$store.commit('aboutUs/setAboutMetaData', result.pageMetadata || {});
      };

      const f2 = async () => {
        this.redirect = null;
        try {
          const defaultParams = {
            slug: this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path,
          };
          const result = await this.$graphql.default.request(GET_ABOUT_US_HEADER, defaultParams) || {};
          this.headerData = result?.cmsPage?.header;
          this.$store.commit('aboutUs/setAboutheaderData', this.headerData || {});
          this.footerData = result?.cmsPage?.footer;
          this.$store.commit('aboutUs/setAboutFooterData', this.footerData || {});
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      try {
        await Promise.allSettled([
          f1(), f2(),
        ]);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
