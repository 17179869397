// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_E07Yj{background:var(--primary-main);margin:0;padding:0}.header__mobile_R6U6E{display:block}@media only screen and (min-width:1440px){.header__mobile_R6U6E{display:none}}.header__container_cfIcv{display:none;margin:0 auto;max-width:1608px;padding:1.6rem 2.4rem;width:100%}@media only screen and (min-width:1440px){.header__container_cfIcv{display:flex;flex-flow:row wrap;flex-wrap:wrap}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header_E07Yj",
	"header__mobile": "header__mobile_R6U6E",
	"header__container": "header__container_cfIcv"
};
module.exports = ___CSS_LOADER_EXPORT___;
