import { onLoad } from '@/helpers/onLoad';

export default ({ $config, route, store }) => {
  const homePagePath = '/';
  const allFurniturePagePath = '/all-furniture';
  // return if the current route is the home page
  if (route.path === homePagePath || route.path === allFurniturePagePath) {
    return;
  }

  const { affirm } = $config;
  if (!affirm?.paymentAffirmApiUrl) {
    return;
  }
  onLoad(() => {
    if (store.state.pageData.globalType === 'PARENT_CATEGORY') {
      return;
    }
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute('src', affirm.paymentAffirmApiUrl);
    scriptEle.setAttribute('async', 'true');
    document.head.appendChild(scriptEle);
    const scriptEle1 = document.createElement('script');
    scriptEle1.innerHTML = `
      window.affirmApiKey = "${affirm.paymentAffirmApiKeySandbox}";
      window.affirmJsUrl = "${affirm.paymentAffirmApiUrl}";
    `;
    document.body.appendChild(scriptEle1);
  });
};
